/***********************************************
         Invenio App RDM Menu Overrides
***********************************************/

#invenio-nav.ui.menu {
    margin-top: 2rem;
    .ui.menu {
        background-color: transparent;
        box-shadow: none;
        .item {
            text-decoration: underline;
            text-decoration-color: @primaryColor;
            a:not(.ui.button) {
                color: @primaryColor;
                padding: 2rem 1rem;
                text-decoration: underline;
                text-decoration-color: @primaryColor;
                &:hover {
                    color: @black;
                    text-decoration: underline;
                    text-decoration-color: @black;
                }
            }
            a:not(.ui.button):has(.icon) {
                text-decoration: none;
                &:hover {
                    text-decoration: none;
                }
            }
        }
        &.transition.visible {
            a.item {
                text-decoration: none;
                &:hover {
                    text-decoration: none;
                }
            }
        }
    }
}

.outer-navbar {
    background-image: None;
    background-color: transparent;
    border-color: transparent;

    #invenio-nav {
        background: @white;
        border-top: 2px solid @brandColor;
        border-bottom: 2px solid @brandColor;
        margin: 1rem 0;
    }

    a {
        color: @primaryColor;
    }

    .header__nav-open#rdm-burger-menu-icon {
        color: @navyBlue;
        margin-inline-start: 1rem;
        border-style: unset !important;
        border-width: 0 !important;

        span {
            top: 1px;

            &::before {
                top: clamp(0.4375rem, 0.4016rem + 0.1795vw, 0.5625rem); // 7px -> 9px
                transition: unset;
            }

            &::after {
                top: clamp(0.875rem, 0.8032rem + 0.3591vw, 1.125rem); // 14px -> 18px
                transition: uset;
            }
        }

        span,
        span::before,
        span::after {
            background-color: @navyBlue;
        }

        &:hover {

            span,
            span::before,
            span::after {
                left: 0px;
                transform: none;
                width: clamp(0.875rem, 1.1623rem, 1.125rem);
                background-color: @darkGray;
            }
	    }
    }
}

.ui.secondary.pointing.menu .item {
    padding: @itemVerticalPadding @itemHorizontalPadding;
    &.active {
        border-color: @primaryColor;
        font-weight: @fontSemibold;
    }
    &:hover {
        border-color: @black;
    }
}

.ui.menu {
    box-shadow: none;
}

.ui.vertical.menu {
    .ui.header.item {
        font-weight: @fontSemibold;
    }
    a.item {
        i {
            text-decoration: none !important;
        }
        color: @darkTextColor;
        font-weight: @fontRegular;
        text-decoration: none;
    }
}

.invenio-administration .side-menu-toggle {
    color: @primaryColor;
}

#invenio-admin-home-nav {
    box-shadow: none;
    background: @lavender;
    border-right: 2px @primaryColor solid;
}

.invenio-administration #invenio-admin-top-nav.ui.menu {
    background: @white;
}

.ui.dropdown .menu.visible.transition {
    min-width: min-content;
    .item:not(.disabled) {
        width: 100%;
        text-decoration: none;
        &:hover, &:focus {
            background-color: @whiteSmoke;
        }
    }
    .item.disabled:not(.active) {
        background-color: transparent;
        color: @slateGray;
        &:hover, &:focus {
            i {
                text-decoration: none !important;
            }
            color: @slateGray;
            background-color: transparent;
            font-weight: @fontRegular;
            text-decoration: none;
        }
    }
    .item:not(.active) {
        background-color: @whiteSmoke;
    }
}

a[href="/communities"] {
    display: none !important;
}

a[href="/communities/new"] {
    display: none !important;
}

a[href="/me/communities"] {
    display: none !important;
}
